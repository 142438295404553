export const i18n_german = {
    translation: {
        lang: "de",
        HomePage: {
            title: "Willkommen bei der SEK",
            aboutUsTitle:"Über uns",
            aboutUsText:"In der Softwareentwicklungskommission (SEK) können Studierende Applikationen entwickeln, die einen coolen Service für Studierende bieten oder die Arbeit von Freiwilligen im VSETH erleichtern. Die SEK ist mit ihrem Weiterbildungsangebot auch der richtige Ort zum Erlernen von neuen Technologien auf einer modernen IT-Infrastruktur in einer aktiven Community.",   
            join: "Wolltest du schon immer ein konkretes Coding-Projekt umsetzen, aber weisst nicht, wo anfangen? Braucht deine Studiorganisation ein neues Tool für einen Spieleabend? Möchtest du deine im Studium erlernten Pythonskills anwenden? Dann kontaktiere uns doch einfach.",
            joinButton: "MITMACHEN",
            newstitle: "Aktuelles",
            newsbeschr_codingsession_title: "Coding Sessions", 
            newsbeschr_codingsession_body: "Wir treffen uns einmal pro Woche zu einer gemeinsamer Coding Session. Jeden Donnerstag Abend bis Semesterende im CAB H 52 von 18 bis 20 Uhr.",
            newsbeschr_codingweekend_title: "Coding Weekend", 
            newsbeschr_codingweekend_body: "Wir organisieren ein Coding Weekend vom 11.04. bis zum 13.04. in Engelberg.",
            newLink1: "Details",
            newLinkCWeekend: "Anmeldung",
            newsbeschr_Title1: "Neuer Raum für die Coding Session",
            newsbeschr_Text1: "Im Frühlingssemester 2023 werden wir die wöchentlich Coding Session neu im CAB E24.2 durchführen. Die Uhrzeit bleibt die Selbe. Jeden Montag im Semester von 18:00 bis 20:00 Uhr."
        },
        Navbar: {
            join: "Mach mit",
            aboutus: "Über uns",
            startseite: "Home",
            projekte: "Projekte",
            links: "Links"
        },
        Join: {
            sessionTitel: "Coding Sessions",
            sessionText: "Jeden Donnerstag Abend treffen wir uns. Und bieten somit eine Anlaufstelle für alle möglichen Fragen in der Webentwicklung. Egal ob du bisher nur eine einfache Informatik Vorlesung hattest oder schon einen eigenen Server hast. An unseren Coding Session sind alle Leute willkommen, die mal den Einstieg in die Webprogrammierung kennen lernen möchten oder sogar vielleicht eines unserer schwereren Projekte übernehmen möchten.",
            sessionPlace: "Jeden Donnerstag im Semester im CAB H 52 von 18 bis 20 Uhr",
            sessionBoard: "Check welche Vorstände anwesend sind.",
            projekteTitle: "Projekte",
            projekteText: "Von ganz einfache informativen Webseiten, konstante Designs für unsere Webseiten oder auch komplexe Prozessabbildungen z.B. in der Buchhaltung haben wir Projekte an denen du dich beteiligen versuchen darfst. Es ist eigentlich für jedes Niveau etwas mit dabei. Und wenn du schon einen eigene Idee hast aber nicht ganz weisst wo, wie, was anfangen, können wir dir helfen einen konkreten Projektplan auf die Beine zu stellen.",
            projekteButton: "Übersicht der laufenden Projekten",
            cwTitel: "Coding Weekend",
            cwText1: "Einmal pro Semester fahren wir zusammen mit den VIS, VMP und AMIV in eine gemütliches Wochenende und coden. Dabei können aktuelle Projekte geplant werden und auch spontan bei bedarf Workshops über verschiedenen Themen abgehalten werden.",
            cwText2: "Auch als Neuling ist man natürlich auch immer am Codingweekend herzlich willkommen. Es werden immer ein paar erfahrenen Entwickler mit dabei sein, mit dem Ziel bei Problemen und Fragen mit dir eine gute Lösung zu entwickeln."
        },
        Project: {
            title: "Aktuelle Projekte",
            text: "Hier findest du die aktuelle noch offenen Projekte von der SEK. Falls du an einem Projekt Interesse hast, melde dich doch unter"
        },
        AboutUs: {
            vorstandtitle: "Der Vorstand",
            vorstandbeschr: "Der Vorstand koordiniert die Eventorganisation, unterstützt die SEK-Mitglieder bei der Umsetzung ihrer Projekte, leitet die Kommission und tauscht sich mit IT-Ausschuss aus. Du kannst ihn über sek[ät]vseth.ethz.ch erreichen.",
            membertitle: "Die Mitglieder",
            memberbeschr: "Die Mitglieder der SEK kümmern sich um unsere Community-Projekte, können im Rahmen des VSETH und studentischen Organisationen entwickeln und coole Projekte vorantreiben."
        },
        Links: {
            title: "Nütliche Links",
            text: "Hier findest du verschiedene Templates und Dokumenationen zu Technologien die wir in der SEK verwenden und nützlich finden.",
            desc: "Beschreibung",
            link: "Link",
            jump: "Zur Seite"
        },
        Profile: {
            name: "Name",
            function: "Funktion",
            task: "Aufgabe",
            mail: "Mail",
            tech: "Tech Stack",
            projects: "Mitgewirkte Projekte",
            website: "Webseite"
        }
    },
};
