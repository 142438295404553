import { Card, Text, Title, Button, Divider, Center, Image, SimpleGrid, Group } from '@mantine/core';
import { useTranslation } from "react-i18next";
import { useStyles } from '../app/styles';



/**
 * The HomePage component renders the welcome page of the application.
 */

 export function shapeOverlay(){
 }

export function HomePage(){
    const { t } = useTranslation();
    const { classes } = useStyles();

    return (
        <>
          <Center>
            <Title>{t("HomePage.title")}</Title>
          </Center>
          <SimpleGrid cols={2}
                  breakpoints={[
                    { maxWidth: 520, cols: 1, spacing: 'sm' },
                  ]}>
              <div>
                <Image src={"/sek_logo.png"} width="100%" alt="sek-logo" />
              </div>
              <div>
                  <Title order={3}>{t("HomePage.aboutUsTitle")}</Title>
                  <Text className={classes.text}>{t("HomePage.aboutUsText")}</Text>
              </div>
          </SimpleGrid>
          <Divider my="sm" />
          <SimpleGrid cols={2}
                  breakpoints={[
                    { maxWidth: 900, cols: 1, spacing: 'sm' },
                  ]}>
            <div>
            <Text className={classes.text}>
            {t("HomePage.join")}
            </Text>
            </div>
            <div style={{margin: 'auto'}}>
              <Button component="a" href="/join">
              {t("HomePage.joinButton")}
              </Button>
            </div>
          </SimpleGrid>
          <Divider my="sm" />
            <Card withBorder={true} shadow="lg">
              <Title order={3}>
                  {t("HomePage.newstitle")}
              </Title>
            
              <Divider my="sm" />
              <Group position='apart'>
                <Title order={5}>{t("HomePage.newsbeschr_codingweekend_title")}</Title>
                <Text className={classes.text} variant='gradient'>05.03.2025</Text>
              </Group>
              
              <Text className={classes.text} size='md'>{t("HomePage.newsbeschr_codingweekend_body")}</Text>
              <Group position='right' mt={5}>
                <Button component="a" href="https://amiv.ch/cw-FS24">
                    {t("HomePage.newLinkCWeekend")}
                </Button>               
              </Group>

              <Divider my="sm" />

              <Group position='apart'>
                <Title order={5}>{t("HomePage.newsbeschr_codingsession_title")}</Title>
                <Text className={classes.text} variant='gradient'>05.03.2025</Text>
              </Group>
              <Text className={classes.text} size='md'>{t("HomePage.newsbeschr_codingsession_body")}</Text>
              <Group position='right' mt={5}>
                <Button component="a" href="/join">
                    {t("HomePage.newLink1")}
                </Button>
              </Group>

            </Card>
        </>
    );
};
